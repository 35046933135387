import {graphql, PageProps} from 'gatsby';
import React from 'react';
import HeaderImage, {Caption} from '../components/HeaderImage';
import {Layout} from '../components/Layout';
import PageWidth from '../components/PageWidth';
import SEO from '../components/SEO';
import {PageWrapper, RichText} from '../styles/page';
import {getImageFromData} from '../utils';
import renderRichText from '../utils/rich-text-renderer';
import {PageQuery} from './__generated__/PageQuery';

const Page = (props: PageProps<PageQuery>) => {
  const data = props.data;
  const pageData = data.contentfulAmpifyWebPage;
  const headerImage = getImageFromData(pageData, 'headerImage');
  return (
    <Layout>
      <SEO
        title={pageData.pageTitle}
        description={pageData.metaDescription}
        keywords={pageData?.metaKeywords?.split(',')}
      />
      <HeaderImage src={headerImage} />
      {pageData.headerImageCaption && (
        <Caption caption={pageData.headerImageCaption} narrow />
      )}
      <PageWrapper>
        <PageWidth narrow>
          <RichText>{renderRichText(pageData?.content)}</RichText>
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageQuery($id: String!) {
    contentfulAmpifyWebPage(id: {eq: $id}) {
      metaKeywords
      metaDescription
      pageTitle
      headerImage {
        ...AmpifyHeaderImage
      }
      headerImageCaption
      content {
        raw
        references {
          ... on ContentfulAmpifyWebSoundcloud {
            ...SoundCloudLink
          }
          ... on ContentfulAsset {
            ...AssetImage
            __typename
          }
          ... on ContentfulAmpifyWebVimeo {
            ...VimeoLink
          }
        }
      }
    }
  }
`;

export default Page;
